import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { AccountManagementState } from "../states";
import { GenericResponse, MarketPlace } from "../models";

@Injectable({
  providedIn: "root",
})
export class isActiveAmazonAccessGuard implements CanActivate {
  @Select(AccountManagementState.getMarketPlacesResponse)
  marketPlaces$: Observable<GenericResponse<MarketPlace[]>>;

  constructor(private router: Router) {}

  findTargetIds(data, id) {
    if (!data || !id) return null;
    return data.find((marketplace: any) => {
      if (marketplace.marketplaceId == id) return true;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot | string,
    state: RouterStateSnapshot | string
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.marketPlaces$.subscribe((d) => {
        if (!d || d?.Result[0]) {
          const selectedMarketPlace = this.findTargetIds(
            d?.Result,
            localStorage.getItem("selectedMarketPlaceId")
          );
          if (
            !selectedMarketPlace ||
            selectedMarketPlace.isAmazonAccessFails == true ||
            selectedMarketPlace.isAmazonConnected == false
          ) {
            resolve(false);
          } else {
            resolve(true);
          }
        }
      });
      
    });
  }
}
